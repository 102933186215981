import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify)

Vue.component('my-component', {
  methods: {
    changeLocale () {
      this.$vuetify.lang.current = 'es'
    },
  },
})
export default new Vuetify({
    icons: {
        iconfont: 'fa',
    },
    lang: {
        locales: { es },
        current: 'es'
    },
    theme: {
      themes: {
        light: {
          gris: '#ebebeb',
          azul: '#011f43',
          naranja: '#e74212',
        },
      },
    }
})