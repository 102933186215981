import Vue from 'vue'
import Router from 'vue-router'
import Raíz_principal from '../views/vistas/raíz_principal.vue'

Vue.use( Router )
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: Raíz_principal,
      children: [
        {
          path: '',
          name: 'inicio',
          component: ()=>import('../views/vistas/vistas/inicio/inicio.vue')
        },
        {
          path: 'nosotros',
          name: 'nosotros',
          component: ()=> import('../views/vistas/vistas/nosotros/nosotros.vue')
        },
        {
          path: 'servicios',
          name: 'servicios',
          component: ()=> import('../views/vistas/vistas/servicios/servicios.vue')
        },
        {
          path: 'cotizacion',
          name: 'cotizacion',
          component: ()=> import('../views/vistas/vistas/cotizacion/cotizacion.vue')
        },
        {
          path: 'contacto',
          name: 'contacto',
        },
        {
          path: 'clientes',
          name: 'clientes',
          component: ()=> import('../views/vistas/vistas/clientes/clientes.vue')
        },
      ]
    },
  ]
})
export default router