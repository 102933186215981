<template lang='pug'>
  v-responsive.pa-0(fluid )  
    v-app
      v-snackbar( v-model="Ayudas.mostrar_snackbar" :timeout='3000') 
        span {{ Ayudas.mensaje_de_snackbar }}
        v-btn( color='naranja' text @click='Ayudas.mostrar_snackbar=false' ) Cerrar
      v-main
        router-view

</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data(){
    return{
      cargando: true,
      link: 'https://cienciaoficcion.com/wp-content/uploads/2014/05/Sitio-en-construccion.jpg',
    }

  },
  created(){
    this.obtener_datos()
    this.obtener_entradas()
    this.obtener_etiquetas()
  },
  watch:{
    Contenido(){
      this.cargando= false
    }
  },
  computed:{
    ...mapState({
      Ayudas : ({Ayudas}) => Ayudas,
    }),
  },
  methods: {
    ...mapActions({
      obtener_datos: 'Landing_set/obtener',
      obtener_entradas: 'Blog/obtener_todos',
      obtener_etiquetas: 'Etiquetas/obtener_todos',
    }),
  },
}
</script>
<style lang="sass" scoped>

.fade-enter-from, .fade-leave-to
    opacity: 0
.fade-enter-active, .fade-leave-active
    transition: opacity 0.5s ease-out
</style>
<style lang="sass">

@font-face 
  font-family: 'Titillium-Web'
  src: url('./assets/fonts/TitilliumWeb-Regular.ttf')
  
h1, h2, h3, h4, h5, h6, h7, span, strong, p
  font-family: 'Titillium-Web'
</style>