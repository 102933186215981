import firebase from "@firebase/app"
require("firebase/firestore")
require('firebase/auth')
require('firebase/storage')
require("firebase/functions");
require("firebase/analytics")

let config  = {
  apiKey: "AIzaSyCzyqw445stNOqq6WIlb5_BIe1HENFO-vM",
  authDomain: "clinicasurejob.firebaseapp.com",
  projectId: "clinicasurejob",
  storageBucket: "clinicasurejob.appspot.com",
  messagingSenderId: "258293381083",
  appId: "1:258293381083:web:f2d20022b201b8bae119fe",
  measurementId: "G-1L4QNVDJ9G"
};

firebase.initializeApp(config)
firebase.analytics()

const db = firebase.firestore()
const _firestore = firebase.firestore
const auth = firebase.auth()
const functions = firebase.functions()
const _auth = firebase.auth
const storage = firebase.storage()
const ColeccionLanding = db.collection('Landing').doc('Contenido')
const ColeccionLandingImagenes = db.collection('Landing').doc('Imágenes')
const Colección_blog = db.collection('Landing').doc('Contenido').collection('Blog')
const Colección_etiquetas = db.collection('Landing').doc('Contenido').collection('Etiquetas')


const enviar_correo_electronico = functions.httpsCallable('correo_electronico-enviar')

export {
  db,
  functions,
  auth,
  _auth,
  storage,
  firebase,
  ColeccionLanding,
  _firestore,
  ColeccionLandingImagenes,
  enviar_correo_electronico,
  Colección_blog,
  Colección_etiquetas,
}