import Vue from 'vue'
import Vuex from 'vuex'
import {Colección_etiquetas} from '@/FirebaseConfig'
Vue.use(Vuex)
export default {
  namespaced:true,
  state:{
    registro: [],
    etiqueta: {
      nombre: '',
      orden: 1,
    },
    etiqueta_filtro: false,
  },
  mutations: {
    limpiar_etiqueta: (state)=>{
      state.etiqueta = {
        nombre: '',
        orden: 1,
      }
    },
    alterar_etiqueta: (state, valor)=>{
      state.etiqueta_filtro = valor
    },
  },
  actions:{
    obtener_todos:({state})=>{
      Colección_etiquetas.orderBy('orden', 'asc').onSnapshot(({docs})=>{
        const registro = []
        docs.map(doc=> registro.push({...doc.data(), id: doc.id}))
        state.registro = registro
      })
    },
    obtener_por_id: ({state}, id_etiqueta)=>{
      state.etiqueta = state.registro.filter(({id})=>id==id_etiqueta)[0]
    },
    seleccionar_etiqueta: ({state, commit}, valor)=>{
      if(valor==state.etiqueta_filtro) commit('alterar_etiqueta', false)
      else commit('alterar_etiqueta', valor)
    },
  },
  getters:{
    registro: ({registro}) => registro,
    nombre_por_id: ({registro}) =>id_etiqueta => registro.filter(({id})=>id==id_etiqueta)[0].nombre,
  },
}
