import Vue from 'vue'
import vuetify from './plugins/vuetify.js'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import {mapGetters, mapState} from 'vuex'
Vue.config.productionTip = false
let app = null

import ScrollAnimation from './plugins/animacionScroll'



Vue.mixin({
	methods:{
		dirigir_a(ruta){
			this.$router.push({name: ruta})
			this.$vuetify.goTo("#navegador")
		},
		separar_arreglo(arreglo, cantidad) {
			const grupo = []
			let separación = []
			arreglo.map( (item, index) => {
				separación.push( item ) 
				if(separación.length == cantidad || index == arreglo.length-1) {
					grupo.push(separación)
					separación = []
				} 
			})
			return grupo
		},
		reasignar_primer_elemento_en_subarreglo(arreglo){
			const arreglo_sub_ordenado = []
			arreglo.map((subarreglo, índice) => {
				if(índice%2==0){
					const subarreglo_ordenado = []
					subarreglo_ordenado.push(subarreglo.pop())
					subarreglo.map(item=>subarreglo_ordenado.push(item))
					arreglo_sub_ordenado.push(subarreglo_ordenado)
				} else {
					arreglo_sub_ordenado.push(subarreglo)
				}
			})
			return arreglo_sub_ordenado
		},
		combinar_subarreglos_a_arreglo(arreglo_con_subarreglos){
			const arreglo = []
			arreglo_con_subarreglos.map(subarreglo => subarreglo.map(item=>arreglo.push(item)))
			return arreglo
		},
		onScroll (e) {
			return e
		},
	},
	filters: {
		fecha_a_letras: val=> {
			let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
			let fecha_formateada
			if(val){
				let dia = val.getDate()
				let mes = val.getMonth()
				let yyy = val.getFullYear()
				fecha_formateada = dia + ' de ' + meses[mes] + ' de ' + yyy
			}
			return fecha_formateada
    },
		fecha_a_día_letra: val=> val.getDate(),
		fecha_a_mes_letra: val=> {
			let meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
			let mes = val.getMonth()
			return meses[mes].substr(0, 3)
		},
		resumen_título_o_subtítulo: val => `${val.substr(0, 33)} ${ val.length > 33 ? '...' : ''}`,
		resumen_título_o_subtítulo_móvil: val => `${val.substr(0, 17)} ${ val.length > 17 ? '...' : ''}`,
	},
	computed:{
		...mapGetters({
			Contenido: 'Landing_set/contenido',
			Registro_de_entradas: 'Blog/registro',
			Registro_de_etiquetas: 'Etiquetas/registro',
		}),
		...mapState({
			Ayudas : ({Ayudas}) => Ayudas,
		}),
		Navegador(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'Navegador'})
		},
		Pie_de_página(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'Pie_de_página'})
		},
		Inicio(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'Inicio'})
		},
		Nosotros(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'Nosotros'})
		},
		Servicios(){
			let objeto = this.$store.getters['Landing_set/contenido_especifico']({pagina: 'Servicios'})
			return {...objeto, servicios: objeto?.servicios.sort((a, b)=>a.orden-b.orden)}
		},
		Cotizacion(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'Cotización'})
		},
		Contacto(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'Contacto'})
		},
		WhatsApp(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'WhatsApp'})
		},
		Clientes(){
			return this.$store.getters['Landing_set/contenido_especifico']({pagina: 'Clientes'})
		},
	}
})

Vue.directive('scrollanimation', ScrollAnimation)


if(!app){
	app = new Vue({
		router,
		store,
		vuetify,
		render: h => h(App)
	}).$mount('#app')
}
